import React from 'react';

import { Button, Card } from '@involve-software/uikit';

import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useNavigation,
  useParams,
  useRouteLoaderData,
} from 'react-router-dom';

import { SelectMethodPaySession } from 'api/payment/selectMethod';

import LoadingOverlay from 'components/LoadingOverlay';
import OptionCard from 'components/OptionCard';

import * as Styled from './index.styled';

const SelectMethodSelection = () => {
  const params = useParams();
  const navigate = useNavigate();
  const navigation = useNavigation();

  const { amount, currency, paymentId, methods, returnUrl } =
    useRouteLoaderData('selectMethod') as SelectMethodPaySession;

  const {
    t,
    i18n: { language },
  } = useTranslation('cactusBanks');

  return (
    <Styled.MethodSelectionCard>
      {navigation.state === 'loading' && <LoadingOverlay />}
      <Styled.PaymentInfo>
        <Styled.Title>
          {t('common:invoice')} #{paymentId}
        </Styled.Title>

        <Styled.PaymentAmount>
          <p>{t('common:paymentAmount')}</p>
          <p>
            {amount} {currency.alias}
          </p>
        </Styled.PaymentAmount>
      </Styled.PaymentInfo>

      <Card.Heading>
        <Card.HeadingTitle>
          <Styled.Title>{t('selectMethod.title')}</Styled.Title>
        </Card.HeadingTitle>
      </Card.Heading>
      <Card.Content $dividerDisabled>
        <Styled.MethodOptions>
          {Object.entries(methods || {}).map(([key]) => (
            <OptionCard
              key={key}
              title={methods[key][language]}
              icon={<img src={`/p2p_pm_images/${key}.png`} alt="" />}
              onClick={() =>
                navigate({
                  pathname: `/select_method/${params.paymentId}/payment`,
                  search: `?method=${key}`,
                })
              }
            />
          ))}
        </Styled.MethodOptions>
        {returnUrl && (
          <Styled.ReturnLink to={returnUrl} target="_blank">
            <Button variant="outlined">{t('cactusBanks:returnButton')}</Button>
          </Styled.ReturnLink>
        )}
      </Card.Content>
    </Styled.MethodSelectionCard>
  );
};

export default SelectMethodSelection;
