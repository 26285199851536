import styled from 'styled-components';

export const OptionCard = styled.button`
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};
  background-color: ${({ theme }) => theme.colors['card-01']};
  border-radius: ${({ theme }) => theme.radius['radius-pr-strong']};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.primitives['s-24']}
    ${({ theme }) => theme.spacing.primitives['s-08']};
  gap: ${({ theme }) => theme.spacing.primitives['s-16']};
  height: 150px;
  cursor: pointer;
`;

export const OptionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 47px;
    max-height: 47px;
  }
`;

export const OptionText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.primitives['s-04']};
  font-family: ${({ theme }) => theme.typography.family};
`;

export const OptionName = styled.div`
  font: ${({ theme }) => theme.typography['paragraph-small']};
`;

export const OptionSubtitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #727b86;
`;
