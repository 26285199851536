import { type LoaderFunction, redirect } from 'react-router-dom';

import {
  getSelectMethodPaySession,
  updateSelectMethodPaySession,
} from 'api/payment/selectMethod';
import { PaymentStatus } from 'api/payment/status';

import { PaymentsType } from 'types/payment';

export const selectMethodLoader: LoaderFunction = async ({
  params,
  request,
}) => {
  if (!params.paymentId) {
    throw new Error('Required params are missing');
  }

  const url = new URL(request.url);

  const { data } = await getSelectMethodPaySession(params.paymentId);

  const isPaymentFinished =
    data.status === PaymentStatus.SUCCESS ||
    data.status === PaymentStatus.REJECTED;

  if (isPaymentFinished) {
    return redirect(
      `/result-page/${params.paymentId}?status=${data.status}&type=${PaymentsType.SELECT_METHOD}`
    );
  }

  if (!data.methodSent && url.pathname.endsWith('/payment')) {
    const method = url.searchParams.get('method');

    if (!method) {
      throw new Error('Required params are missing');
    }

    try {
      await updateSelectMethodPaySession(params.paymentId!, method);

      const { data: updatedData } = await getSelectMethodPaySession(
        params.paymentId
      );

      if (updatedData.status === PaymentStatus.REJECTED) {
        return updatedData.failedUrl
          ? redirect(updatedData.failedUrl)
          : redirect(
              `/result-page/${params.paymentId}?status=${data.status}&type=${PaymentsType.SELECT_METHOD}`
            );
      }

      return updatedData;
    } catch (e) {
      throw new Error(e as string);
    }
  }

  return data;
};

export const selectMethodRedirectLoader: LoaderFunction = async ({
  params,
}) => {
  return redirect(`/select_method/${params.paymentId}/methods`);
};
