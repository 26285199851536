export enum ResponseErrorCode {
  ACCESS_DENIED = 451,
}

export enum ServiceErrorCode {
  INVALID_PAYMETHOD = 1,
}

export const ERROR_CODE_TKEYS = {
  [ServiceErrorCode.INVALID_PAYMETHOD]: 'invalidPaymethod',
};
